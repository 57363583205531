/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        //CallBeck contact form 7
        $(".banner-form .wpcf7").on('wpcf7:submit', function (event) {
          setTimeout(function () {
            $(".banner-form .wpcf7-response-output").css("display", "none");
          }, 3000);
        });

        $('.testimonial-slider').slick({
          dots: true,
          infinite: false,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: '<div class="btn-slick btn-slick__prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></div>',
          nextArrow: '<div class="btn-slick btn-slick__next"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows:false,
                infinite: true,
                dots: true
              }
            }
          ]
        });

        //hamburger menu

        $('.hamburger-menu').on('click', function () {
          $(this).toggleClass('open');
          $('.nav-header').slideToggle();
          $(".sub-menu").removeClass("sub-menu-transition");
        });


        $(".btn-sub-menu").on('click',function () {
          $(".sub-menu").addClass("sub-menu-transition");
        });

        $(".btn-back").on('click',function () {
          $(".sub-menu").removeClass("sub-menu-transition")
        });

        $(window).on('resize', function () {
          if ($(window).width() > 991) {
            $('.nav-header').removeAttr('style');
            $('.hamburger-menu').removeClass('open');
          }
        });


        function windowSize(){
          if ($(window).width() <= '990') {
            $('.brand.col-md-6').removeClass('col-md-6');
          }else{
            $('.brand').addClass('col-md-6');
          }
        }
        $(window).on('load resize',windowSize);

        $('#menu-our-services>li>a').each(function() {
          if ($(this).prop('href') === window.location.href) {
            $(this).addClass('current-page');
          }
        });

        $('.sub-menu>li>a').on('click',function () {
          $('#menu-item-30').addClass('.current-menu-item');
        })
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
    setTimeout(function() {
      $('#cookie-notice').css({"visibility":"hidden", "opacity": 0});
    }, 5500);
  $('#cn-more-info').appendTo('#cn-notice-text');

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

